/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'logo.svg',
  logoalt: 'logo-white.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_immotoken.scss',
  name: 'Immotoken',
  phone: '+31 20 21 01 225',
  email: 'info@immotokens.be',
  website: 'https://www.immotokens.be',
  glossary: true,
  langFileName: 'immotokens.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: false,
};
