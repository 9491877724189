import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { storage } from '@/firebase';
import { Downloads, State, StateSlice } from '@/store/models';

export const GET_DOWNLOADS_ERROR = 'GET_DOWNLOADS_ERROR';
export const GET_DOWNLOADS_SUCCESS = 'GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_PROCESSING = 'GET_DOWNLOADS_PROCESSING';

export interface DownloadPath {
  collection: keyof State,
  id?: string,
  properties: string | string[],
}

export default <Module<Downloads, State>> {
  state: {},
  getters: {
    getDownloadObjectFromState: (state): Function =>
      ({ collection, id, properties: property }: DownloadPath): any | undefined => {
        if (!state[collection]) {
          return undefined;
        }
        if (id && !state[collection][id]) {
          return undefined;
        }

        return id ? state[collection][id][(property as string)] : state[collection][(property as string)];
      },
    getDownloadsByPath: (state, getters): Function =>
      ({ collection, id, properties: property }: DownloadPath): [string, string][] | undefined => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return found && found.status === 'success' ? found.payload : undefined;
      },
    isDownloadsProcessing: (state, getters): Function =>
      ({ collection, id, properties: property }: DownloadPath): boolean => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return !!found && found.status === 'processing';
      },
    didDownloadsFail: (state, getters): Function =>
      ({ collection, id, properties: property }: DownloadPath): boolean => {
        const found = getters.getDownloadObjectFromState({ collection, id, properties: property });
        return !!found && found.status === 'error';
      },
  },
  mutations: {
    [GET_DOWNLOADS_ERROR](state: Downloads, { path: { collection, id, properties: property }, error }: { path: DownloadPath, error: any }): void {
      const stateSlice = id ? state[collection][id][property] : state[collection][property];
      stateSlice.status = 'error';
      stateSlice.error = error.message || 'Something went wrong';
    },
    [GET_DOWNLOADS_SUCCESS](state: Downloads, { path: { collection, id, properties: property }, downloads }: { path: DownloadPath, downloads: [string, string][] }): void {
      const stateSlice = id ? state[collection][id][property] : state[collection][property];
      stateSlice.status = 'success';
      stateSlice.payload = downloads;
    },
    [GET_DOWNLOADS_PROCESSING](state: Downloads, { path: { collection, id, properties: property } }: { path: DownloadPath }): void {
      const stateSlice: StateSlice = {
        status: 'processing',
        error: '',
        name: '',
        payload: null,
      };

      // Construct the state - root properties need to be reactive
      if (!state[collection]) {
        Vue.set(state, `${collection}`, {});
      }

      state[collection] = {
        ...state[collection],
        ...(!id && { [property as string]: stateSlice }),
        ...(id && { [id]: {
          ...state[collection][id],
          [property as string]: stateSlice,
        } }),
      };
    },
  },
  actions: {
    async getDownloads(
      { commit, rootState, getters }: ActionContext<Downloads, State>,
      paths: DownloadPath | DownloadPath[],
      ): Promise<void> {
        // Validate and serialize
        const downloadList: { path: DownloadPath, downloadNames: string[] }[] = [];
        const inputErrors: Error[] = [];
        const pathArray = !Array.isArray(paths) ? [paths] : paths;

        pathArray.forEach(({ collection, id, properties }): void => {
          if (!rootState[collection]) {
            inputErrors.push(new Error(`InputError: The collection name '${collection}' was not found or it is null`));
            return;
          }

          const targetObj = !id ?
            rootState[collection] : Array.isArray(rootState[collection]) ?
            rootState[collection]!.find((obj): boolean => obj.id === id) :
            rootState[collection]![id];

          if (!targetObj) {
            inputErrors.push(new Error(`InputError: The id ${id} was not found in collection ${collection}`));
            return;
          }

          const propertyArray = Array.isArray(properties) ? (properties as string[]) : [(properties as string)];
          propertyArray.forEach((property): void => {
            // TODO: Check if property is downloadable

            if (!targetObj[property]) {
              inputErrors.push(new Error(`InputError: Property ${property} is not found in collection ${collection} with id ${id}`));
              return;
            }
            // 'id' is only inlcuded if it is not undefined
            const path = { collection, ...(id && { id }), properties: property };
            commit(GET_DOWNLOADS_PROCESSING, { path });

            // Check if already dowloaded
            const dlItem = getters.getDownloadsByPath(path);
            if (dlItem) {
              commit(GET_DOWNLOADS_SUCCESS, { path, downloads: dlItem });
              return;
            }

            downloadList.push({ path, downloadNames: targetObj[property] });
          });
        });

        // Exit execution if even one of the inputs were wrong
        if (inputErrors.length) {
          throw inputErrors;
        }

        // Download
        await downloadList.forEach(({ path, downloadNames }): void => {
          // Assuming downloadNames are absolute paths
          const promiseArray = downloadNames.map((downloadName): Promise<string> => downloadName ? storage.ref().child(downloadName).getDownloadURL() : Promise.resolve());
          Promise.all(promiseArray)
            .then((values): void => {
              // Zip the two arrays as the order of the promises is preserved
              const downloads = values.map((value, index): [string, string] => [downloadNames[index], value]);
              commit(GET_DOWNLOADS_SUCCESS, { path, downloads });
            }).catch((error): void => {
              // NOTE: if any of the images failed to download it throws
              commit(GET_DOWNLOADS_ERROR, { path, error });
            });
        });
    },
  },
};
