import { Module, ActionContext } from 'vuex';
import { ethers } from 'ethers';
import { functions, db, firebase } from '@/firebase';
import Vue from 'vue';
import { State, BlockchainStateSlice, InitialStateSlice } from '@/store/models';
import to from 'await-to-js';
import Moralis from 'moralis';
import { EvmChain } from '@moralisweb3/common-evm-utils';
import detectEthereumProvider from '@metamask/detect-provider';
import * as MUTATIONS from '@/store/constants';
import assetHandlerContractABI from '../../contracts/assetHandlerContract.json';

const isProductionProject = process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID!.search('staging') === -1;

const trackBlockchainTransactionError = (transactionName, errorMessage, investorId, transactionHash = ''): void => {
  const dateNowTimestamp = firebase.firestore.Timestamp.now();
  const dateNow = dateNowTimestamp.toDate();
  dateNow.setFullYear(dateNow.getFullYear() + 1);
  const expiresAt = firebase.firestore.Timestamp.fromDate(dateNow);

  const errorDocREf = db.collection('blockchainTransactionsErrors').doc();
  errorDocREf.set({
    transactionName,
    transactionHash,
    errorMessage,
    investorId,
    createdDateTime: dateNowTimestamp,
    updatedDateTime: dateNowTimestamp,
    expiresAt,
  });
};

export default <Module<BlockchainStateSlice, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'isMetamaskAccountConnected', payload.isConnected);
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS, payload });
    },
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING });
    },
    [MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'blockchainTokens', { tokenName: payload.tokenName, tokensAmount: payload.tokensAmount });
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, payload });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING](state: BlockchainStateSlice): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, error });
    },
    [MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'blockchainTokens', { tokenName: payload.tokenName, tokensAmount: payload.tokensAmount });
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, payload });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING](state: BlockchainStateSlice, payload): void {
      Vue.set(state, 'operations', { status: 'processing', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING, payload });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR](state: BlockchainStateSlice, error: string): void {
      Vue.set(state, 'operations', { status: 'error', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, error });
    },
    [MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS](state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'operations', { status: 'success', name: MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS, payload });
    },
  },
  actions: {
    async syncBlockchainTokens(
      { commit, state }: ActionContext<BlockchainStateSlice, State>,
      { investorId }: { investorId: string},
    ): Promise<void> {
      commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_PROCESSING);

      try {
        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        if (providerDetected && state.isMetamaskAccountConnected) {
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          try {
            await Moralis.start({
              apiKey: process.env.VUE_APP_MORALIS_API_KEY,
            });
          } catch (e) {
            trackBlockchainTransactionError(
              'syncBlockchainTokens',
              (e as Error).message || 'Failed to start the Moralis API',
              investorId,
            );
            commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, 'Failed to start the Moralis API');
          }

          const [responseError, response] = await to(Moralis.EvmApi.nft.getWalletNFTs({
            address: signerAddress,
            format: 'decimal',
            disableTotal: false,
            tokenAddresses: [isProductionProject ? '0x113787aa2499236667043368d0A7b1CC699F8766' : '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a'],
            chain: isProductionProject ? EvmChain.POLYGON : EvmChain.MUMBAI,
          }));

          if (responseError) {
            trackBlockchainTransactionError(
              'syncBlockchainTokens',
              responseError.message || 'Failed to retrieve blockchain tokens data from the api',
              investorId,
            );
            commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, responseError.message);
          }

          if (response!.pagination.total === 0) {
            return;
          }

          const blockchainData = {
            tokenName: response!.result[0].symbol,
            tokensAmount: response!.pagination.total,
          };

          commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_SUCCESS, blockchainData);
        } else {
          commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, 'Make sure you have MetaMask extension installed on your browser.');
        }
      } catch (e) {
        trackBlockchainTransactionError(
          'syncBlockchainTokens',
          'Something went wrong.',
          investorId,
        );
        commit(MUTATIONS.UPDATE_BLOCKCHAINTOKENS_ERROR, 'Something went wrong.');
      }
    },
    updateIsMetamaskAccountConnect(
      { commit }: ActionContext<BlockchainStateSlice, State>,
      { isConnected }: { isConnected: boolean },
    ): void {
      commit(MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_PROCESSING);
      commit(MUTATIONS.UPDATE_IS_METAMASK_CONNECTED_SUCCESS, { isConnected });
    },
    async claimBlockchainTokens(
      { commit }: ActionContext<BlockchainStateSlice, State>,
      { paymentId, tokensAlreadyClaimed, blockchainTokensIds, investmentId, investorId }:
      { paymentId: string, tokensAlreadyClaimed: number, blockchainTokensIds: string[], investmentId: string, investorId: string },
    ): Promise<void> {
      commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING);

      try {
        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        if (providerDetected) {
          commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING, { paymentId });
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const investorAddress = await signer.getAddress();
          const proxyContractAddress = isProductionProject ? '0x113787aa2499236667043368d0A7b1CC699F8766' : '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a';
          const assetHandler = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);

          let counter = 0;
          const batchSize = 100;
          const blockchainTokensToBeClaimedIds = blockchainTokensIds.slice(tokensAlreadyClaimed);

          while (counter < blockchainTokensToBeClaimedIds.length) {
            const iterationsLeft = blockchainTokensToBeClaimedIds.length - counter;
            const currentBatchSize = iterationsLeft < batchSize ? iterationsLeft : batchSize;

            const currentBatchOfTokensToClaim = blockchainTokensToBeClaimedIds.slice(counter, counter + currentBatchSize);
            const [getSignatureError, signature] = await to(functions.httpsCallable('signBlockchainTransaction')({ investorAddress, currentBatchOfTokensToClaim }));

            if (getSignatureError) {
              trackBlockchainTransactionError(
                'claimBlockchainTokens',
                'Error signing the transaction.',
                investorId,
                '',
              );
              commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Failed to sign the transaction. Please try again.');
              return;
            }

            const tx = await assetHandler.claimUnits(currentBatchOfTokensToClaim, signature!.data, { gasLimit: 3000000 });
            const txResult = await tx.wait();

            counter += currentBatchSize;

            if (txResult.status === 1) {
              const [updatePaymentBlockchainTokensClaimedError] = await to(
                functions.httpsCallable('paymentBlockchainTokensClaimed')({ paymentId, investmentId, currentBatchSize, transactionHash: txResult.transactionHash }),
              );

              if (updatePaymentBlockchainTokensClaimedError) {
                trackBlockchainTransactionError(
                  'claimBlockchainTokens',
                  'Update Payment Blockchain Tokens Claimed failed',
                  investorId,
                  tx.hash,
                );
                commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed. Please try again.');
                return;
              }

              if (counter === blockchainTokensToBeClaimedIds.length) {
                commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS);
              }
            } else if (txResult.status !== 1) {
              trackBlockchainTransactionError(
                'claimBlockchainTokens',
                'Something went wrong.',
                investorId,
                tx.hash,
              );
              commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed. Please try again.');
              return;
            }
          }
        } else {
          commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Make sure you have MetaMask extension installed on your browser.');
        }
      } catch (e: any) {
        trackBlockchainTransactionError(
          'claimBlockchainTokens',
          e.message || 'Something went wrong.',
          investorId,
          e.transactionHash ? e.transactionHash : '',
        );
        commit(MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR, 'Transaction for claiming the tokens failed.');
      }
    },
  },
  getters: {},
};
